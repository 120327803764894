import Layout from '@/page/layout/home'

export default [
    {
        path: '/workbench',
        name: 'workbench',
        component: Layout,
        redirect: '/workbench/index',
        children: [
            {
                path: 'index',
                name: 'workbench-index',
                component: () => import(/* webpackChunkName: "workbench" */ '@/views/workbench/index.vue'),
                meta: {
                    title: '工作台',
                    isAuth: true,
                }
            },
        ]
    },
]
