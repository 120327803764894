import {createRouter, createWebHistory} from 'vue-router'
import PageRouter from './page/' // 页面路由
import ViewsRouter from './views/' // 页面路由
import store from '../store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {validatenull} from '@/util/validate'
import {getToken} from '@/util/auth'
import * as dd from 'dingtalk-jsapi'
import {getENV,} from 'dingtalk-jsapi/lib/env';
import {closeNotify, showNotify, showToast} from 'vant';

NProgress.configure({showSpinner: false});
const {platform, version, appType,} = getENV();
const base = process.env.NODE_ENV === "production" ? "/" : "/"
const router = createRouter({
    history: createWebHistory(base), // hash模式：createWebHashHistory，history模式：createWebHistory
    routes: [
        ...PageRouter, ...ViewsRouter
    ]
})


// 路由守卫
router.beforeEach(async (to, from, next) => {
    const meta = to.meta || {};
    const appConfig = store.getters.appConfig
    if (getToken()) {
        //如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
        if (validatenull(store.getters.userInfo)) {
            await store.dispatch('LogOut')
            next({
                path: '/tenant-login',
                query: {
                    appId: appConfig.id
                }
            })
        } else {
            next()
        }
    } else {
        //判断是否需要认证，没有登录访问去登录页
        if (meta.isAuth === false) {
            next()
        } else {
            next({
                path: '/tenant-login',
                query: {
                    appId: appConfig.id
                }
            })
        }
    }
});

router.afterEach((to) => {
    NProgress.done();
    /* 路由发生变化修改页面title */
    let _title = to.params.title || to.query.title || to.meta.title;
    if (_title) {
        if (version) {
            dd.biz.navigation.setTitle({
                title: _title,//控制标题文本，空字符串表示显示默认文本
            });
        } else {
            document.title = _title;
        }
    } else {
        if (version) {
            dd.biz.navigation.setTitle({
                title: '',//控制标题文本，空字符串表示显示默认文本
            });
        } else {
            document.title = '';
        }
    }
    setTimeout(() => {
        closeNotify();
    }, 2000);
});


router.beforeResolve((to, from, next) => {
    const {rotateOption, iosBounce = true,keepScreenOn = false} = to.meta;
    if (rotateOption && rotateOption.rotate) {
        if (version) {
            const {showStatusBar, clockwise} = rotateOption;
            dd.device.screen.rotateView({
                showStatusBar,
                clockwise
            });
        }else{
            showNotify({type: 'warning', message: '该页面配置了旋转屏幕功能，\n请在钉钉客户端打开以获得更好体验。'})
        }
    } else {
        if (version) {
            dd.device.screen.resetView({});
        }
    }

    //如果是ios & 钉钉环境，处理IOS页面弹跳
    if (platform === 'ios' && version) {
        if (iosBounce) {
            dd.ui.webViewBounce.enable()
        } else {

            dd.ui.webViewBounce.disable()
        }
    }

    //设置屏幕常亮
    if(['android','ios'].includes(platform) && version){
        dd.setKeepScreenOn({
            isKeep: keepScreenOn,
            success: () => {
                if(keepScreenOn){
                    // showToast(`屏幕常亮已 【开启】`)
                }
            },
        }).catch((e)=> {
            // showToast(`开启或关闭屏幕常亮失败`)
            console.info(e)
        });
    }

    next();
})

export default router
