// import Layout from '@/page/layout/home'
import LayoutNoFooter from '@/page/layout/index'

export default [
    {
        path: '/activity',
        name: 'activity',
        component: LayoutNoFooter,
        redirect: '/activity/index',
        children: [
            {
                path: 'index',
                name: 'activity-index',
                component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/index.vue'),
                meta: {
                    title: '活动首页',
                    isAuth: true,
                    keepAlive: true,
                    iosBounce:false,//是否IOS弹跳（默认是）
                }
            },
            {
                path: 'joined',
                name: 'my-joined-page-index',
                component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/joinedActivityPageIndex'),
                meta: {
                    title: '我参与的活动',
                    isAuth: true
                }
            },
            {
                path: 'detail',
                name: 'activity-detail',
                component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/activityDetailIndex'),
                props: route => ({
                    id: route.query.id,
                }),
                meta: {
                    title: '活动详情',
                    isAuth: true
                }
            },
            {
                path: 'detail-n',
                name: 'activity-n-detail',
                component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/activityNDetailIndex'),
                props: route => ({
                    id: route.query.id,
                }),
                meta: {
                    title: '活动详情',
                    isAuth: true
                }
            },
        ]
    },
]
