<template>
  <div class="flexCol wholePage">
    <div class="AContent">

      <router-view v-slot="{ Component, route }">
        <keep-alive>
          <component
              v-if="$route.meta.keepAlive"
              :is="Component"
              :key="route.meta.usePathKey ? route.path : undefined"
          />
        </keep-alive>
        <component
            v-if="!$route.meta.keepAlive"
            :is="Component"
            :key="route.meta.usePathKey ? route.path : undefined"
        />
      </router-view>

    </div>
  </div>
</template>
<script>
import {reactive, toRefs} from 'vue'

export default {
  setup() {
    const state = reactive({				// reactive--监听

    })
    return {
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
