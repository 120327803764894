/**
 * 是否有某种角色
 * v-hasRole="'a'"
 * v-hasRole="['a','b']" (包含管理员)
 * v-hasRole:only="['a','b']" (不包含管理员)
 *
 */

import store from '@/store/';

export default (el, binding) => {
    const {value, arg = ''} = binding
    //仅仅是传入的角色生效
    const isOnlyFlag = 'only' === arg.toLocaleString()
    const admin_alias = ['administrator', 'admin'];
    const roles = store.getters.roles;
    if (value && value instanceof Array && value.length > 0) {
        const roleFlag = value

        const hasRole = roles.some(role => {
            return isOnlyFlag ? roleFlag.includes(role) : (admin_alias.includes(role) || roleFlag.includes(role))
        })

        el.style.display = hasRole ? '' : 'none'
    } else if (value && typeof value === 'string') {
        const roleValue = value
        const hasRole = roles.some(role => {
            return isOnlyFlag ? roleValue === role : (admin_alias.includes(role) || roleValue === role)
        })

        el.style.display = hasRole ? '' : 'none'
    } else {
        throw new Error(`请设置角色权限标签值（字符串）,支持数组与单个值`)
    }
}
