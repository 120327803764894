export default [
    {
        path: '/login',
        name: 'login',
        redirect: '/tenant-login'
    },
    {
        path: '/tenant-login',
        name: 'tenantLogin',
        component: () => import( /* webpackChunkName: "page" */ '@/page/login/tenantLogin'),
        props: route => ({
            appId: route.query.appId,//appId：后台钉钉配置应用id
            routerName: route.query.routerName,//临时跳转的路由名称
        }),
        meta: {
            keepAlive: false,
            isAuth: false
        }
    },
    {
        path: '/404',
        component: () => import( /* webpackChunkName: "page" */ '@/components/error-page/404'),
        name: '404',
        meta: {
            keepAlive: true,
            isAuth: false
        }

    },
    {
        path: '/403',
        component: () => import( /* webpackChunkName: "page" */ '@/components/error-page/403'),
        name: '403',
        meta: {
            keepAlive: true,
            isAuth: false
        }
    },
    {
        path: '/500',
        component: () => import( /* webpackChunkName: "page" */ '@/components/error-page/500'),
        name: '500',
        meta: {
            keepAlive: true,
            isAuth: false
        }
    },
    {
        path: '/',
        name: '主页',
        redirect: '/tenant-login',
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
]
