import request from '@/router/axios';

export const getSquadDetail = (id) => {
  return request({
    url: '/api/typt-common/squad/squad-detail',
    method: 'get',
    params: {
      id
    }
  })
}


export const getUserInfo = (id) => {
  return request({
    url: '/api/blade-user/detail',
    method: 'get',
    params: {
      id
    }
  })
}


export const switchCzcStatus = (studentId,targetStatus) => {
  return request({
    url: '/api/blade-student/switch-czc-status',
    method: 'post',
    params: {
      studentId,
      targetStatus
    }
  })
}

/**
 * 获取app配置详情
 * @param id
 * @returns {AxiosPromise}
 */
export const getAppConfig = (appId) => {
  return request({
    url: '/api/tenant_app_config/tenantappconfig/detail4login',
    method: 'get',
    params: {
      appId
    }
  })
}

/**
 * 获取工作台配置
 * @param tenantId
 * @returns {AxiosPromise}
 */
export const getWorkbenchList = (tenantId) => {
  return request({
    url: '/api/blade-auth/getWorkbenchTenantByTenantId',
    method: 'get',
    params: {
      tenantId
    }
  })
}
