
import website from '@/config/website'
import {getStore, setStore} from "@/util/store";
import {getAppConfig, getWorkbenchList} from "@/api/base/base";
import {getReportConfig} from "@/api/report/report";

const common = {
  state: {
    screen: -1,
    website: website,
    appConfig:getStore({name: 'appConfig'}) || {},
    workbenchList:getStore({name: 'workbenchList'}) || [],
    reportConfig:getStore({name: 'reportConfig'}) || {},
  },
  actions:{
    fetchAppConfig({commit,dispatch}, appId){
      return new Promise((resolve, reject) => {
        getAppConfig(appId).then(res => {
          const _appConfig = res.data?.data||{}
          commit('SET_APP_CONFIG', _appConfig);
          commit('SET_TENANT_ID', _appConfig.tenantId);

          dispatch('fetchWorkbenchList', _appConfig.tenantId);
          dispatch('fetchReportConfig', _appConfig.tenantId);

          resolve(res);
        }).catch(e =>{
              reject(e)
         })
      })
    },
    fetchWorkbenchList({commit}, tenantId){
      return new Promise((resolve, reject) => {
        getWorkbenchList(tenantId).then(res => {
          const _workbenchList = res.data?.data||[]
          commit('SET_WORKBENCH_LIST', _workbenchList);
          resolve(res);
        }).catch(e =>{
              reject(e)
         })
      })
    },

    fetchReportConfig({commit}, tenantId){
      return new Promise((resolve, reject) => {
        getReportConfig(tenantId).then(res => {
          const _workbenchList = res.data?.data||{}
          commit('SET_REPORT_CONFIG', _workbenchList);
          resolve(res);
        }).catch(e =>{
          reject(e)
        })
      })
    },
  },
  mutations: {
    SET_SCREEN: (state, screen) => {
      state.screen = screen;
    },
    SET_APP_CONFIG:(state, appConfig) => {
      state.appConfig = appConfig
      setStore({name: 'appConfig', content: state.appConfig})
    },
    SET_WORKBENCH_LIST:(state, workbenchList=[])=> {
      state.workbenchList = workbenchList
      setStore({name: 'workbenchList', content: state.workbenchList})
    },
    SET_REPORT_CONFIG:(state, reportConfig= {})=> {
      state.reportConfig = reportConfig
      setStore({name: 'reportConfig', content: state.reportConfig})
    },
  }

}
export default common
