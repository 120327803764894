import LayoutNoFooter from '@/page/layout/index'

export default [
    {
        path: '/squad-notice',
        component: LayoutNoFooter,
        children: [
            {
                path: 'index',
                name: 'squad-notice-index',
                component: () => import(/* webpackChunkName: "squad-notice" */ '@/views/squad-notice/index'),
                meta: {
                    title: '班级通知',
                    isAuth: true,
                }
            },
            {
                path: 'detail',
                name: 'squad-notice-detail',
                component: () => import(/* webpackChunkName: "squad-notice" */ '@/views/squad-notice/noticeDetail'),
                props: route => ({
                    id: route.query.id,
                }),
                meta: {
                    title: '班级通知详情',
                    isAuth: true,
                }
            },
            {
                path: 'read-status',
                name: 'squad-notice-read-status',
                component: () => import(/* webpackChunkName: "squad-notice" */ '@/views/squad-notice/readStatus'),
                props: route => ({
                    id: route.query.id,
                }),
                meta: {
                    title: '阅读情况',
                    isAuth: true,
                }
            },
        ]
    },
]
