/**
 * 长按或者点击
 */
export default (el, binding, vNode) => {
    if (typeof binding.value !== 'function') {
        const compName = vNode.context.name
        let warn = `[longPress:] provided expression '${binding.expression}' is not a function, but has to be `
        if (compName) { warn += `Found in component '${compName}' ` }
        console.warn(warn)
    }


    // 运行函数
    const handler = (e) => {
        // 执行传递给指令的方法
        binding.value(e)
    }

    // 取消计时器
    el.addEventListener('click', handler)
    el.addEventListener('mouseout', handler)
    el.addEventListener('touchend', handler)
    el.addEventListener('touchcancel', handler)
}
