import request from '@/router/axios';
import website from "@/config/website";

export const loginByUsername = (tenantId, username, password, type, key, code) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    'Captcha-Key': key,
    'Captcha-Code': code,
  },
  params: {
    tenantId,
    username,
    password,
    grant_type: (website.captchaMode ? "captcha" : "password"),
    scope: "all",
    type
  }
});

export const loginByPhone = (tenantId, phone, smsId, smsCode) => request({
  url: '/api/blade-auth/oauth/tokenBySms',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    'Sms-Id': smsId,
    'Sms-Code': smsCode,
  },
  params: {
    tenantId,
    phone,
    grant_type: "smsCode",
    scope: "all"
  }
});

export const loginByPhone2 = (tenantId, username, code) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    'Captcha-Key': username,
    'Captcha-Code': code,
    'User-Type': website.userType
  },
  params: {
    tenantId,
    username,
    grant_type: (website.captchaMode ? "smsCaptcha" : "password"),
  }
});

export const dingtalkLogin = (tenantId, authCode) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    'User-Type': website.userType,
    'Dingtalk-Auth-Code': authCode,
  },
  params: {
    tenantId,
    grant_type: 'dingtalk',
  }
});

export const sendLoginSmsCode = (tenantId, code, key, phone) => request({
  url: '/api/blade-auth/oauth/smsCode',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    'Captcha-Key': key,
    'Captcha-Code': code,
  },
  params: {
    phone
  }
});

export const loginBySocial = (tenantId, source, code, state) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId
  },
  params: {
    tenantId,
    source,
    code,
    state,
    grant_type: "social",
    scope: "all",
  }
})

export const refreshToken = (refresh_token, tenantId) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId
  },
  params: {
    tenantId,
    refresh_token,
    grant_type: "refresh_token",
    scope: "all",
  }
});

export const registerGuest = (form, oauthId) => request({
  url: '/api/blade-user/register-guest',
  method: 'post',
  params: {
    tenantId: form.tenantId,
    name: form.name,
    account: form.account,
    password: form.password,
    oauthId
  }
});

export const getButtons = () => request({
  url: '/api/blade-system/menu/buttons',
  method: 'get'
});

export const getCaptcha = () => request({
  url: '/api/blade-auth/oauth/captcha',
  method: 'get'
});

export const logout = () => request({
  url: '/api/blade-auth/oauth/logout',
  method: 'get'
});

export const getUserInfo = () => request({
  url: '/api/blade-auth/oauth/user-info',
  method: 'get'
});

export const sendLogs = (list) => request({
  url: '/api/blade-auth/oauth/logout',
  method: 'post',
  data: list
});

export const clearCache = () => request({
  url: '/api/blade-auth/oauth/clear-cache',
  method: 'get'
});

export const switchCurType = (targetType) => request({
  url: '/api/blade-user/switch-cur-type',
  method: 'post',
  params:{
    targetType
  }
});

export const switchCurChild = (childId) => request({
  url: '/api/blade-user/switch-cur-child',
  method: 'post',
  params:{
    childId
  }
});

export const switchCurSquad = (squadId) => request({
  url: '/api/blade-user/switch-cur-squad',
  method: 'post',
  params:{
    squadId
  }
});

export const switchDept = (currentDeptId) => request({
  url: '/api/blade-user/switch-dept',
  method: 'post',
  params:{
    currentDeptId
  }
});


export const getMyChildren = () => request({
  url: '/api/blade-user/my-children',
  method: 'get',
  params:{
  }
});

export const getTeachSquads = () => request({
  url: '/api/typt-common/squad/teach-squads',
  method: 'get',
  params:{
  }
});


export const getSquadStudents = (squadId) => request({
  url: '/api/blade-student/student-kv-lis',
  method: 'get',
  params:{
    squadId
  }
});
