// loading.js
import LoadingComponent from './loading.vue'
import { createVNode, render } from 'vue'

let $data;

export default {
    install: (app, options) => {
        // 在这里编写插件代码
        if (!$data) {
            // LoadingComponent 为自己写的组件
            $data = createVNode(LoadingComponent, {}, {
                default: () => createVNode()
            });
            $data.appContext = app._context; // 这句很关键，关联起了数据
            render($data, document.body);
        }
        $data.component.exposed.showValue = false;
        const loading = {
            show(param={text:'',bg:'',speed:undefined}) {
                $data.component.exposed.show(param);
            },
            hide() {
                $data.component.exposed.hide();
            }
        };

        if (!app.$loading) {
            app.$loading = loading;
        }
        app.config.globalProperties.$loading = app.$loading;
    }
}


