/**
 * 当前用户身份：1老师，3家长
 * v-hasType="[1,3]":老师和家长
 * v-hasType="1":仅仅老师
 * v-hasType="[1]":仅仅老师
 */

import store from '@/store/';

export default (el, binding,vnode) =>{
        const {value,} = binding
        const curType = store.getters.userType

        if (value && value instanceof Array && value.length > 0) {
            const types = value
            const hasCurType = types.some(ele => {
                return ele === curType;
            });
            if (!hasCurType) {
                el.style.display = 'none'
            }

            else{
                el.style.display = ''
            }

        } else if (value && typeof value === 'number') {
            const hasCurType = curType === value;
            if (!hasCurType) {
                el.style.display = 'none'
            }
            else{
                el.style.display = ''
            }
        } else {
            throw new Error(`请设置当前身份类型值（数字类型）,支持数组，或者单个值`)
        }
}
