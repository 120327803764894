<template>
  <van-tabbar v-model="active" @change="handleChange" :safe-area-inset-bottom="true">
    <template v-for="item in list" :key="item.name">
      <van-tabbar-item :name="item.name" :icon="getIcon(item)">{{ item.mc }}</van-tabbar-item>
    </template>
  </van-tabbar>
</template>
<script setup>
import {reactive, toRefs, onMounted, onActivated, computed, watch, nextTick} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from "vuex";
const store = useStore();
    const state = reactive({				// reactive--监听
      curType: computed(() => store.getters.userType),
      active: 'index',
      list: [{
        mc: '成长册',
        name: 'index',
        icons: [
          "home.png",
          "home_active.png"
        ]
      }, {
        mc: '班级圈',
        name: 'moment-index',
        icons: [
          "cycle.png",
          "cycle_active.png"
        ]
      }, {
        mc: '应用中心',
        name: 'workbench-index',
        icons: [
          "moment.png",
          "moment_active.png"
        ]
      }, {
        mc: '发展评价',
        name: 'fzpj-index',
        icons: [
          "fzpj.png",
          "fzpj_active.png"
        ]
      }, {
          mc: '我的',
          name: 'my',
          icons: [
            "my.png",
            "my_active.png"
          ]
        }
      ]
    })

 const {active,list} = toRefs(state)

    onMounted(() => {
      state.active = route.name
    })
    onActivated(() => {
      state.active = route.name
    });
    const getIcon = computed(()=> {
      return(item) =>{
        const isActive = item.name === state.active;
        return  require(`@/assets/image/tab-bar/${item.icons[isActive ? 1 : 0]}`)
      }
    })

    const router = useRouter()
    const route = useRoute()


    const handleChange = (active) =>{

      if(active == 'reportIndex'){
        if(state.curType == 1){
          active = 'reportIndex';
        }else if(state.curType == 3){
          active = 'reportStudentIndex';
        }
      }

      router.replace({
        name:active
      })
    }

    watch(()=> route.name,val => {
      let _activeName = val;
      if([`reportIndex`,`reportStudentIndex`].includes(val)){
         _activeName = `reportIndex`;
      }
      state.active = _activeName;
    })

</script>
<style lang="scss" scoped>
.van-hairline--top-bottom:after, .van-hairline-unset--top-bottom:after {
  border-width: 0 !important;
  border-top-width: 1px !important;
}

</style>
