import request from '@/router/axios';
import website from "@/config/website";
import {setToken, setRefreshToken, removeToken, removeRefreshToken} from '@/util/auth'
import {loginByUsername,loginByPhone, dingtalkLogin, getUserInfo, logout, refreshToken} from '@/api/user'
import {Toast} from 'vant';

export const remoteValidatePhone = (phone) => request({
  url: '/api/blade-auth/oauth/validate-phone',
  method: 'get',
  params: {
    phone
  }
});

/**
 *  发送登录验证码
 * @returns
 */
export const getCaptcha = (tenantId,phone) => request({
  url: '/api/blade-auth/oauth/validate-code',
  method: 'get',
  headers: {
    'Tenant-Id': tenantId
  },
  params: {
    phone
  }
});

//根据手机验证码登录
export const doLoginByPhone = (userInfo) => {
  return new Promise((resolve, reject) => {
    loginByPhone(userInfo.tenantId, userInfo.phone, userInfo.smsCode).then(res => {
      const data = res.data;
      console.log(data)
      if (data.error_description) {
        Toast(data.error_description)
        reject(data.error_description);
      } else {
        setUser(Object.assign({},{
          id:data.user_id,
          avatar:data.avatar,
          nick_name:data.nick_name,
          type:data.type,
          typeall:data.typeall,
          role_name:data.role_name
        }));
        setToken(data.access_token);
        setRefreshToken(data.refresh_token);
        resolve(data);
      }
    }).catch(error => {
      reject(error);
    })
  })
}


//根据手机验证码登录
export const doDingtalkLogin = (userInfo) => {
  return new Promise((resolve, reject) => {
    dingtalkLogin(userInfo.tenantId, userInfo.dingtalkAuthCode).then(res => {
      const data = res.data;
      if (data.error_description) {
        Toast(data.error_description)
        reject(data.error_description);
      } else {
        setUser(Object.assign({},{
          id:data.user_id,
          avatar:data.avatar,
          nick_name:data.nick_name,
          type:data.type,
          typeall:data.typeall,
        }));

        setToken(data.access_token);
        setRefreshToken(data.refresh_token);
        resolve(data);
      }
    }).catch(error => {
      reject(error);
    })
  })
}


// 登出
export const doLogout = () => {
  return new Promise((resolve, reject) => {
    logout().then(() => {
      removeToken();
      removeRefreshToken();
      removeUser();
      resolve();
    }).catch(error => {
      reject(error)
    })
  })
}


//刷新token
export const doRefreshToken = (refreshTokenV,tenantId) => {
  window.console.log('handle refresh token')
  return new Promise((resolve, reject) => {
    refreshToken(refreshTokenV, tenantId).then(res => {
      console.log(res)
      const data = res.data;
      setToken(data.access_token);
      setRefreshToken(data.refresh_token);
      resolve();
    }).catch(error => {
      reject(error)
    })
  })
}


