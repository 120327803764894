/**
 * 是否有多个孩子
 */

import store from '@/store/';

export default (el, binding) =>{
        const children = store.getters.children||[]
        const flag = !(children && children.length > 0)
        el.style.display = flag ? '' : 'none'
}
