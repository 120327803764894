import hasRole from './permission/hasRole.js'
import hasType from './permission/hasType.js'
import hasBoth from './permission/hasBoth.js'
import hasChildren from './permission/hasChildren.js'
import hasSquads from './permission/hasSquads.js'
import longPress from './permission/longPress.js'
import moveOut from './permission/moveOut.js'
import hasNoChildren from './permission/hasNoChildren.js'
import hasNoSquads from './permission/hasNoSquads.js'
import loadingJS from './permission/loading.js'

export default function directive(app){
  app.directive('hasRole', hasRole)
  app.directive('hasType', hasType)
  app.directive('hasBoth', hasBoth)
  app.directive('hasChildren', hasChildren)
  app.directive('hasSquads', hasSquads)
  app.directive('longPress', longPress)
  app.directive('moveOut', moveOut)
  app.directive('hasNoChildren', hasNoChildren)
  app.directive('hasNoSquads', hasNoSquads)
}
