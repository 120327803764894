<template>
  <div class="flexCol wholePage">

    <div class="AContent">
      <router-view v-slot="{ Component, route }">
        <keep-alive>
          <component
              v-if="$route.meta.keepAlive"
              :is="Component"
              :key="route.meta.usePathKey ? route.path : undefined"
          />
        </keep-alive>
        <component
            v-if="!$route.meta.keepAlive"
            :is="Component"
            :key="route.meta.usePathKey ? route.path : undefined"
        />
      </router-view>
    </div>
    <foot class="footer" />
  </div>
</template>
<script>
import {reactive, toRefs, onMounted, ref} from 'vue'
// import foot from '@/page/footer/footer.vue'
import foot from '@/page/footer/footer2.vue'

export default {
  components: {foot},
  setup() {
    const state = reactive({				// reactive--监听
    })
    const footRef = ref(null)

    onMounted(() => {

    })

    return {
      footRef,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped>
.AContent{
  margin-bottom: 50px;
  //margin-bottom: 64px;
}

</style>
