<template>
  <router-view class="router-view"/>
</template>

<script>
import {reactive, toRefs, onMounted, inject, watch} from 'vue'
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import {validatenull} from "@/util/validate";
import {calcDate} from "@/util/date.js";
import {getStore} from "@/util/store.js";
import website from "@/config/website";
import {getConfigParams} from "@/api/dd/dd";
import {allowMultipleToast, showNotify, showToast} from "vant";

export default {
  name: 'App',
  setup() {
    const {dd, dVersion, platform, appType} = inject("global");

    allowMultipleToast();

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const state = reactive({				// reactive--监听
      transitionName: 'slide-left',
      refreshTime: '',
      //刷新token锁
      refreshLock: false
    })

    router.afterEach((to) => {
      // store.dispatch("setConfigStatus", 0)
      if (to.meta.dingConfig) {
        handleDingConfig();
      }
    })

    router.beforeEach((to, from) => {
      if (to.meta.index > from.meta.index) {
        state.transitionName = 'slide-left'
      } else if (to.meta.index < from.meta.index) {
        state.transitionName = 'slide-right'
      } else {
        state.transitionName = ''
      }
    })

    onMounted(() => {
      refreshToken();
    });

    const refreshToken = () => {
      state.refreshTime = setInterval(() => {
        const token = getStore({
          name: "tokenA",
          debug: true
        }) || {};
        const date = calcDate(token.datetime, new Date().getTime());
        if (validatenull(date)) return;
        if (date.seconds >= website.tokenTime && !state.refreshLock) {
          state.refreshLock = true;
          store.dispatch("refreshToken")
              .then(() => {
                state.refreshLock = false;
              })
              .catch(() => {
                state.refreshLock = false;
              });
        }
      }, 10000);
    }

    const handleDingConfig = () => {
      // if(!(dVersion && ['ios','android'].includes(platform.toLowerCase()))){
      //   showNotify({type: 'warning', message:'钉钉鉴权失败：非移动端钉钉环境'});
      //   return
      // }
      // if(dVersion || dVersion.length === 0){
      //   showNotify({type: 'warning', message:'钉钉鉴权失败：非钉钉环境'});
      //   return
      // }
      if (platform !== 'ios' && platform !== 'android') {
        showNotify({type: 'warning', message: '钉钉鉴权失败：非android或者ios环境'});
        return
      }
      let _url = '';
      if (platform === 'ios') {
        _url = window.location.href;
      } else if (platform === 'android') {
        _url = website.appLink;
      }
      // console.info( 'href',window.location.href)
      getConfigParams(_url).then(res => {
        const {agentId, corpId, timeStamp, nonceStr, signature} = res.data;
        dd.config({
          agentId, // 必填，微应用ID
          corpId,//必填，企业ID
          timeStamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，自定义固定字符串。
          signature, // 必填，签名
          type: 0,   //选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
          jsApiList: [
            'startRecord',
            'stopRecord',
            'playAduio',
            'stopAudio',
            'onRecordEnd',
            'onPlayAudioEnd',
            'downloadAudio',
            'biz.util.setScreenKeepOn'
          ] // 必填，需要使用的jsapi列表，注意：不要带dd。
        });
        dd.error(function (err) {
          // console.info('dd error: ' + JSON.stringify(err));
          // showToast(`钉钉鉴权失败：${JSON.stringify(err)}`)
          // showNotify({type: 'warning', message: err.errorMessage});
          dd.device.notification.hidePreloader({});
        })//该方法必须带上，用来捕获鉴权出现的异常信息，否则不方便排查出现的问题
      })
    }

    return {
      ...toRefs(state)				//reactive在return时候需要toRefs来转换成响应式对象
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
