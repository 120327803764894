import {setToken, setRefreshToken, removeToken, removeRefreshToken} from '@/util/auth'
import {remoteValidatePhone, getCaptcha, doLoginByPhone} from "@/api/login/login";
import {showNotify} from 'vant';
import {setStore, getStore} from '@/util/store'
import {isURL, validatenull} from '@/util/validate'
import {deepClone} from '@/util/util'
import website from '@/config/website'
import {
    loginByUsername,
    switchCurType,
    loginByPhone,
    loginByPhone2,
    loginBySocial,
    getUserInfo,
    logout,
    refreshToken,
    dingtalkLogin, switchCurChild, switchCurSquad, getMyChildren, getTeachSquads
} from '@/api/user'
import md5 from 'js-md5'
import {computed} from "vue";

const user = {
    state: {
        tenantId: getStore({name: 'tenantIdA'}) || '',
        userInfo: getStore({name: 'userInfoA'}) || {},
        permission: getStore({name: 'permissionA'}) || {},
        roles: getStore({name: 'rolesA'}) || [],
        token: getStore({name: 'tokenA'}) || '',
        refreshToken: getStore({name: 'refreshTokenA'}) || '',
        children:getStore({name: 'children'}) || [],
        curChild:getStore({name: 'curChild'}) || {},
        squads:getStore({name: 'squads'}) || [],
        curSquad:getStore({name: 'curSquad'}) || {},
        curRole:getStore({name: 'curRole'}) || '',
        childrenCount:getStore({name: 'childrenCount'}) || 0,//此数据不会随着切换身份而清空，用于判断有没有孩子
    },
    actions: {
        SetChildren({state,commit}, children=[]){
            return new Promise((resolve, reject) => {
                getMyChildren().then(res =>{
                    const _children = res.data.data||[]
                    commit('SET_CHILDREN', _children);
                    commit('SET_CHILDREN_COUNT', _children.length);
                    resolve(res)
                })

            })
        },
        SetSquads({state,commit}, squads=[]){
            return new Promise((resolve, reject) => {
                getTeachSquads().then(res => {
                    const squads = res.data.data||[]
                    console.info('squads',squads)
                    commit('SET_SQUADS', squads);
                    resolve(res)
                })
            })
        },
        SwitchCurChild({state,commit}, childId){
            return new Promise((resolve, reject) => {
                switchCurChild(childId).then(res => {
                    const data = res.data.data;
                    if (data.error_description) {
                        showNotify({type: 'danger', message: data.error_description});
                    } else {
                        let userInfo =  state.userInfo
                        commit('SET_CUR_CHILD', childId);
                        commit('SET_CHILDREN', data.children);
                        commit('SET_USER_INFO', Object.assign(userInfo,{nick_name:data.nick_name,child_id:childId}));
                        resolve(childId);
                    }
                    resolve();
                })
            })
        },
        SwitchCurSquad({state,commit}, squadId){
            return new Promise((resolve, reject) => {
                switchCurSquad(squadId).then(res => {
                    const data = res.data.data;
                    console.info(data)
                    if (data.error_description) {
                        showNotify({type: 'danger', message: data.error_description});
                    } else {
                        let userInfo =  state.userInfo
                        commit('SET_CUR_SQUAD', squadId);
                        commit('SET_USER_INFO', Object.assign(userInfo,{cur_squad_id:squadId}));
                        resolve(squadId);
                    }
                    resolve();
                })
            })
        },
        //钉钉自动登录
        DingtalkLogin({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                dingtalkLogin(userInfo.tenantId, userInfo.dingtalkAuthCode).then(res => {
                    const data = res.data;
                    const children = data.children || [];
                    const squads = data.squads || [];
                    if (data.error_description) {
                        showNotify({type: 'danger', message: data.error_description});
                        reject(data.error_description);
                    } else {
                        commit('SET_TOKEN', data.access_token);
                        commit('SET_REFRESH_TOKEN', data.refresh_token);
                        commit('SET_TENANT_ID', data.tenant_id);
                        commit('SET_CHILDREN', children);
                        commit('SET_SQUADS', squads);
                        commit('SET_CHILDREN_COUNT', children.length);
                        let user_info = data;
                        delete user_info.children
                        delete user_info.squads
                        commit('SET_USER_INFO', user_info);
                        commit('SET_CUR_CHILD',user_info.child_id);
                        commit('SET_CUR_SQUAD',user_info.cur_squad_id);
                        commit('SET_CUR_ROLE', user_info.current_role || '');

                        const roleNames = (user_info.role_name || '').split(',')
                        commit('SET_ROLES', roleNames);

                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                })
            })
        },
        //根据用户名登录
        LoginByUsername({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                loginByUsername(userInfo.tenantId, userInfo.username, /**md5(userInfo.password)**/userInfo.password, userInfo.type, userInfo.key, userInfo.code).then(res => {
                    const data = res.data;
                    const children = data.children||[];
                    const squads = data.squads || [];
                    console.log(data);
                    if (data.error_description) {
                        showNotify({type: 'danger', message: data.error_description});
                    } else {
                        commit('SET_TOKEN', data.access_token);
                        commit('SET_REFRESH_TOKEN', data.refresh_token);
                        commit('SET_TENANT_ID', data.tenant_id);
                        commit('SET_CHILDREN', children);
                        commit('SET_CHILDREN_COUNT', children.length);
                        commit('SET_SQUADS', squads);
                        let user_info = data;
                        delete user_info.children
                        delete user_info.squads
                        commit('SET_USER_INFO', user_info);
                        commit('SET_CUR_CHILD',user_info.child_id);
                        commit('SET_CUR_SQUAD',user_info.cur_squad_id);
                        commit('SET_CUR_ROLE', user_info.current_role || '');

                        const roleNames = (user_info.role_name || '').split(',')
                        commit('SET_ROLES', roleNames);

                    }
                    resolve();
                }).catch(error => {
                    reject(error);
                })
            })
        },
        //切换当前用户类型
        SwitchCurType({state,commit}, targetType) {
            /**
             * targetType:3切换为家长，1切换为老师，9切换为管理员
             */
            return new Promise((resolve, reject) => {
                switchCurType(targetType).then(res => {
                    const data = res.data.data;
                    const children = data.children||[];
                    const squads = data.squads||[];

                    let userInfo = state.userInfo;
                    // console.log(data);
                    // console.log(userInfo);
                    if (data.error_description) {
                        showNotify({type: 'danger', message: data.error_description});
                    } else {
                        commit('SET_USER_INFO', Object.assign(userInfo,{nick_name:data.nick_name,current_type:data.current_type}));
                        if(targetType === 1){
                            commit('SET_CHILDREN', []);
                            commit('SET_CUR_CHILD','');
                            commit('SET_SQUADS', squads);
                            commit('SET_CUR_SQUAD',userInfo.cur_squad_id);
                            commit('SET_CUR_ROLE',website.switchMapObj[1]);
                        }else if(targetType === 3){
                            commit('SET_CHILDREN', children);
                            commit('SET_CHILDREN_COUNT', children.length);
                            commit('SET_CUR_CHILD',userInfo.child_id);
                            commit('SET_SQUADS', []);
                            commit('SET_CUR_SQUAD','');
                            commit('SET_CUR_ROLE',website.switchMapObj[3]);
                        }else if(targetType === 9){
                            commit('SET_CHILDREN', []);
                            commit('SET_CUR_CHILD','');
                            commit('SET_SQUADS', []);
                            commit('SET_CUR_SQUAD','');
                            commit('SET_CUR_ROLE',website.switchMapObj[9]);
                        }
                    }
                    resolve();
                }).catch(error => {
                    reject(error);
                })
            })
        },
        //根据手机号登录
        LoginByPhone({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                loginByPhone(userInfo.tenantId, userInfo.phone, userInfo.smsId, userInfo.smsCode).then(res => {
                    const data = res.data;
                    const children = data.children || [];
                    const squads = data.squads || [];
                    console.info(data)
                    if (data.error_description) {
                        showNotify({type: 'danger', message: data.error_description});
                    } else {
                        commit('SET_TOKEN', data.access_token);
                        commit('SET_REFRESH_TOKEN', data.refresh_token);
                        commit('SET_TENANT_ID', data.tenant_id);
                        commit('SET_CHILDREN', children);
                        commit('SET_CHILDREN_COUNT', children.length);
                        commit('SET_SQUADS', squads);
                        let user_info = data;
                        delete user_info.children
                        delete user_info.squads
                        commit('SET_USER_INFO', user_info);
                        commit('SET_CUR_CHILD', user_info.child_id);
                        commit('SET_CUR_SQUAD', user_info.cur_squad_id);
                        commit('SET_CUR_ROLE', user_info.current_role || '');

                        const roleNames = (user_info.role_name || '').split(',')
                        commit('SET_ROLES', roleNames);
                    }
                    resolve();
                }).catch(error => {
                    reject(error);
                })
            })
        },
        LoginByPhone2({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                loginByPhone2(userInfo.tenantId, userInfo.phone, userInfo.smsCode).then(res => {
                    const data = res.data;
                    const children = data.children||[];
                    const squads = data.squads||[];
                    // console.log(data)
                    if (data.error_description) {
                        showNotify({type: 'danger', message: data.error_description});
                        reject(data.error_description);
                    } else {
                        commit('SET_TOKEN', data.access_token);
                        commit('SET_REFRESH_TOKEN', data.refresh_token);
                        commit('SET_TENANT_ID', data.tenant_id);
                        commit('SET_CHILDREN', children);
                        commit('SET_CHILDREN_COUNT', children.length);
                        commit('SET_SQUADS', squads);
                        let user_info = data;
                        delete user_info.children
                        delete user_info.squads

                        commit('SET_USER_INFO', user_info);
                        commit('SET_CUR_CHILD',user_info.child_id);
                        commit('SET_CUR_SQUAD', user_info.cur_squad_id);
                        commit('SET_CUR_ROLE', user_info.current_role || '');

                        const roleNames = (user_info.role_name || '').split(',')
                        commit('SET_ROLES', roleNames);

                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                })
            })
        },
        //根据第三方信息登录
        LoginBySocial({commit}, userInfo) {
            return new Promise((resolve) => {
                loginBySocial(userInfo.tenantId, userInfo.source, userInfo.code, userInfo.state).then(res => {
                    const data = res.data;
                    if (data.error_description) {
                        showNotify({type: 'danger', message: data.error_description});
                    } else {
                        commit('SET_TOKEN', data.access_token);
                        commit('SET_REFRESH_TOKEN', data.refresh_token);
                        commit('SET_USER_INFO', data);

                        const roleNames = (data.role_name || '').split(',')
                        commit('SET_ROLES', roleNames);
                    }
                    resolve();
                })
            })
        },
        //获取用户信息
        GetUserInfo({commit}) {
            return new Promise((resolve, reject) => {
                getUserInfo().then((res) => {
                    const data = res.data.data;
                    commit('SET_ROLES', data.roles);
                    resolve(data);
                }).catch(err => {
                    reject(err);
                })
            })
        },
        //刷新token
        refreshToken({state, commit}) {
            window.console.log('handle refresh token')
            return new Promise((resolve, reject) => {
                refreshToken(state.refreshToken, state.tenantId).then(res => {
                    const data = res.data;
                    commit('SET_TOKEN', data.access_token);
                    commit('SET_REFRESH_TOKEN', data.refresh_token);
                    resolve();
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登出
        LogOut({commit}) {
            return new Promise((resolve, reject) => {
                logout().then(() => {
                    commit('SET_TENANT_ID', '');
                    commit('SET_TOKEN', '');
                    commit('SET_REFRESH_TOKEN', '');
                    commit('SET_USER_INFO', {});
                    commit('SET_ROLES', []);

                    commit('SET_CHILDREN', []);
                    commit('SET_CUR_CHILD', '');

                    commit('SET_SQUADS', []);
                    commit('SET_CUR_SQUAD', '');
                    commit('SET_CUR_ROLE', '');
                    commit('SET_CHILDREN_COUNT', 0);

                    removeToken();
                    removeRefreshToken();
                    resolve();
                }).catch(error => {
                    reject(error)
                })
            })
        },
        //注销session
        FedLogOut({commit}) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '');
                commit('SET_ROLES', []);
                commit('SET_REFRESH_TOKEN', '');
                removeToken();
                removeRefreshToken();
                resolve();
            })
        }
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            setToken(token);
            state.token = token;
            setStore({name: 'tokenA', content: state.token})
        },
        SET_REFRESH_TOKEN: (state, refreshToken) => {
            setRefreshToken(refreshToken)
            state.refreshToken = refreshToken;
            setStore({name: 'refreshTokenA', content: state.refreshToken})
        },
        SET_TENANT_ID: (state, tenantId) => {
            state.tenantId = tenantId;
            setStore({name: 'tenantIdA', content: state.tenantId})
        },
        SET_USER_INFO: (state, userInfo) => {
            state.userInfo = userInfo;
            setStore({name: 'userInfoA', content: state.userInfo})
        },
        SET_ROLES: (state, roles=[]) => {
            state.roles = roles;
            setStore({name: 'rolesA', content:  state.roles})
        },
        SET_CHILDREN: (state, children=[]) => {
            const _children = state.children||[]
            let replaceFlag = false;
            if(children.length !== _children.length){
                replaceFlag = true;
            }
            if(!replaceFlag){
                children.forEach(ele => {
                   const flag = _children.some(_ele => _ele.id === ele.id)
                    if(!flag){
                        replaceFlag = true;
                    }
                })
            }

            if(replaceFlag){
                state.children = children;
                setStore({name: 'children', content: state.children})
            }
        },
        SET_CUR_CHILD: (state, childId) => {
            let _curChild = {}
            const _children = state.children||[]
            if(childId){
                _children.forEach(ele => {
                    if(ele.id === childId){
                        _curChild = ele;
                    }
                })
            }else{
                if(_children && _children.length > 0){
                    _curChild = _children[0]
                }
            }
            state.curChild = _curChild;
            setStore({name: 'curChild', content: state.curChild})
        },
        SET_CUR_ROLE:(state,curRole)=> {
            state.curRole = curRole;
            setStore({name: 'curRole', content: state.curRole})
        },
        SET_CHILDREN_COUNT:(state,childrenCount)=> {
            state.childrenCount = childrenCount;
            setStore({name: 'childrenCount', content: state.childrenCount})
        },
        SET_SQUADS: (state, squads=[]) => {
            const _squads = state.squads
            let replaceFlag = true;
            // if(squads.length !== _squads.length){
            //     replaceFlag = true;
            // }
            // if(!replaceFlag){
            //     squads.forEach(ele => {
            //         const flag = _squads.some(_ele => _ele.id === ele.id)
            //         if(!flag){
            //             replaceFlag = true;
            //         }
            //     })
            // }

            if(replaceFlag){
                state.squads = squads;
                setStore({name: 'squads', content: state.squads})
            }
        },
        SET_CUR_SQUAD: (state, squadId) => {
            let _curSquad = {}
            const _squads = state.squads||[]
            if(squadId){
                _squads.forEach(ele => {
                    if(ele.id === squadId){
                        _curSquad = ele;
                    }
                })
            }else{
                if(_squads && _squads.length > 0){
                    _curSquad = _squads[0]
                }
            }
            state.curSquad = _curSquad;
            setStore({name: 'curSquad', content: state.curSquad})
        },
    }

}
export default user
