import LayoutNoFooter from '@/page/layout/index'

export default [
    {
        path: '/squad-album',
        component: LayoutNoFooter,
        children: [
            {
                path: 'index',
                name: 'squad-album-index',
                component: () => import(/* webpackChunkName: "squad-album" */ '@/views/squad-album/index'),
                meta: {
                    title: '班级相册',
                    isAuth: true,
                }
            },
            {
                path: 'detail',
                name: 'squad-album-detail',
                component: () => import(/* webpackChunkName: "squad-album" */ '@/views/squad-album/detail'),
                props: route => ({
                    id: route.query.id,
                }),
                meta: {
                    title: '相册详情',
                    isAuth: true,
                }
            },
        ]
    },
]
