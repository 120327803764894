import { createStore } from 'vuex'
import getters from './getters'
import user from './modules/user.js'
import common from './modules/common'

export default createStore({
    modules: {
        user,
        common
    },
    getters
})
