// import Layout from '@/page/layout/home'
import LayoutNoFooter from '@/page/layout/index'

export default [
    {
        path: '/story',
        name: 'story',
        component: LayoutNoFooter,
        redirect: '/story/index',
        children: [
            {
                path: 'index',
                name: 'story-index',
                component: () => import(/* webpackChunkName: "story" */ '@/views/story/index'),
                meta: {
                    title: '故事',
                    isAuth: true,
                    iosBounce:false,//是否IOS弹跳（默认是）
                    rotateOption:{
                        rotate:false,//是否旋转
                        showStatusBar: false,//是否显示statusbar (iOS)
                        clockwise:true,//是否顺时针
                    }
                }
            },
            {
                path: 'detail',
                name: 'story-detail-index',
                component: () => import(/* webpackChunkName: "story" */ '@/views/story/storyDetailIndex'),
                props: route => ({
                    id: route.query.id
                }),
                meta: {
                    title: '故事详情',
                    isAuth: true,
                    keepAlive: false,
                }
            },
            {
                path: 'detail2',
                name: 'storys-detail-index',
                component: () => import(/* webpackChunkName: "story" */ '@/views/story/components/StorysDetail'),
                props: route => ({
                    id: route.query.id
                }),
                meta: {
                    title: '故事详情',
                    isAuth: true,
                    iosBounce:false,//是否IOS弹跳（默认是）
                    keepAlive: true,
                }
            }
        ]
    },
]
