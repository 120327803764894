// import Layout from '@/page/layout/home'
import LayoutNoFooter from '@/page/layout/index'

export default [
    {
        path: '/moment',
        name: 'moment',
        component: LayoutNoFooter,
        redirect: '/moment/index',
        children: [{
            path: 'index',
            name: 'moment-index',
            component: () => import(/* webpackChunkName: "moment" */ '@/views/moment/index.vue'),
            meta: {
                title: '班级圈',
                isAuth:true
            }
        }]
    },
]
