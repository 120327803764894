import { showNotify} from 'vant';

const getters = {
    appConfig: state => state.common.appConfig,
    workbenchList: state => state.common.workbenchList,
    reportConfig: state => state.common.reportConfig,
    userInfo: state => state.user.userInfo,
    tenantId: state => state.user.tenantId || state.common.appConfig?.tenantId,
    screen: state => state.common.screen,
    token: state => state.user.token,
    roles: state => state.user.roles,
    curRole: state => state.user.curRole,
    childrenCount: state => state.user.childrenCount,
    userType: (state,getter) => {//即CurType
        const userInfo = getter.userInfo;
        const type = userInfo.type;
        let current_type = userInfo.current_type;
        if(type && ![1,3].includes(type)){
            showNotify({type:"warning",message:`用户类型异常:type值为${type}`});
            return '';
        }

        if(!(current_type && [1,3].includes(current_type))){
            current_type = type;
            // showNotify({type:"warning",message:`用户类型异常:current_type值为${current_type}`});
            return type;
        }

        if(type === 3){
            return 3;
        }else if(type === 1){
            if(!current_type || current_type === 1){
                return 1;
            }else if(current_type === 3){
                return 3;
            }else{
                showNotify({type:"warning",message:`用户类型异常:current_type值为${current_type}`});
                return ''
            }
        }else{
            showNotify({type:"warning",message:`用户类型异常:type值为${type}`});
            return '';
        }
    },
    children: state => state.user.children,
    curChild: state => state.user.curChild,
    squads: state => state.user.squads,
    curSquad: state => state.user.curSquad
}
export default getters
