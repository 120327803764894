/**
 * 全局变量,用于注入到组件使用
 * @author DJ.
 */

import * as dd from 'dingtalk-jsapi'
import {getENV} from 'dingtalk-jsapi/lib/env';
import {showImagePreview, showNotify, showToast} from "vant";
import {useRouter, useRoute} from 'vue-router'
import website from "@/config/website";
import {getConfigParams} from "@/api/dd/dd";

const {platform, version, appType} = getENV();

const doPreviewImage = (images, index) => {
    showImagePreview({
        images: images,
        startPosition: index,
        closeable: true,
        getContainer: "body"
    });
}


const handleDingConfig = () => {
    // if(!(dVersion && ['ios','android'].includes(platform.toLowerCase()))){
    //   showNotify({type: 'warning', message:'钉钉鉴权失败：非移动端钉钉环境'});
    //   return
    // }
    // if(dVersion || dVersion.length === 0){
    //   showNotify({type: 'warning', message:'钉钉鉴权失败：非钉钉环境'});
    //   return
    // }
    return new Promise(async(resolve, reject) => {
        if (platform !== 'ios' && platform !== 'android') {
            // showNotify({type: 'warning', message: '钉钉鉴权失败：非android或者ios环境'});
            showToast("非android或者ios环境")
            reject('钉钉鉴权失败：非android或者ios环境');
        }
        let _url = '';
        if(true || platform === 'ios'){
            _url = window.location.href;
        }else if(platform === 'android'){
            _url = website.appLink;
        }
        console.info('url---->' + _url)
        await getConfigParams(_url).then( async (res) => {
             const {agentId, corpId, timeStamp, nonceStr, signature} = res.data;
            const res00 = await dd.config({
                agentId, // 必填，微应用ID
                corpId,//必填，企业ID
                timeStamp, // 必填，生成签名的时间戳
                nonceStr, // 必填，自定义固定字符串。
                signature, // 必填，签名
                type: 0,   //选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
                jsApiList: [
                    'startRecord',
                    'stopRecord',
                    'playAduio',
                    'stopAudio',
                    'onRecordEnd',
                    'onPlayAudioEnd',
                    'downloadAudio'
                ] // 必填，需要使用的jsapi列表，注意：不要带dd。
            });
            console.info("res00" + JSON.stringify(res00))
              dd.error(function (err) {
                console.info('dd error: ' + JSON.stringify(err));
                dd.ready(() => {
                    dd.device.notification.hidePreloader({});
                })
                  showToast(err.errorMessage)
                reject(err.errorMessage);

            })//该方法必须带上，用来捕获鉴权出现的异常信息，否则不方便排查出现的问题
        })
    })

}

export default {
    dd:dd,
    dVersion:version,
    platform,
    appType,
    doPreviewImage,
    handleDingConfig
}
