import {isProduction} from './env'
/**
 * 全局配置文件
 */
export default {
  corpId:'dingefa8f4a9ccf21807',//dinga1c6825e2fb4687235c2f4657eb6378f //
  userType:"app",
  title: "saber",
  logo: "S",
  key: 'saber',//配置主键,目前用于存储
  projectName: '电子成长档案',
  clientId: 'saber', // 客户端id
  clientSecret: 'saber_secret', // 客户端密钥
  tenantIdKey:'Tenant-Id',
  tenantId: undefined, //根据appId从后台获取，进行状态管理，此处不在维护
  appIdKey:"App-Id",//tenantId配置应用code
  appId:"",//1702149902340984833//
  appLink:undefined,//钉钉app应用入口[鉴权需要]
  captchaMode: true, // 是否开启验证码模式
  tokenTime: 3000,
  tokenHeader: 'Blade-Auth',
  //http的status默认放行列表
  statusWhiteList: [],
  //代理地址的前缀默认值'/api/upload',正式环境替换为''
  propsHttpPrefix:  isProduction ?  '' : '/api/upload/',
  staticHttpPrefix:  isProduction ?  '' : '/api/',
  switchMapObj:{
    1:'teacher',
    3:'parent',
    9:'admin',
  },
  httpUrl:'https://house.121xuexi.com',//后台地址，用于拼接图片绝对地址
  imageMaxSize: 20*1024*1024,//上传文件大小限制，0为不限制
  videoMaxSize: 500*1024*1024,//上传文件大小限制，0为不限制
}
