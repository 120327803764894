import Layout from '@/page/layout/home'
import LayoutNoFooter from '@/page/layout/index'
import bizRouters from './biz/index'

export default [
    ...bizRouters,
    {
        path: '/index',
        name: 'home',
        component: Layout,
        redirect: '/index/index',
        children: [{
            path: 'index',
            name: 'index',
            component: () => import(/* webpackChunkName: "views" */ '@/views/index/index.vue'),
            meta: {
                title: '首页',
                isAuth:true,
                dingConfig:false
            }
        }, {
            path: 'my',
            name: 'my',
            component: () => import(/* webpackChunkName: "views" */ '@/views/my/index.vue'),
            meta: {
                title: '我的',
                keepAlive: true,
                isAuth:true
            }
        }, {
            path: 'teacherIndex',
            name: 'teacherIndex',
            component: () => import(/* webpackChunkName: "views" */ '@/views/index/components/TeacherIndex.vue'),
            meta: {
                title: '成长册',
                isAuth:true
            }
        }]
    },
    {
        path: '/report',
        name: 'report',
        component: LayoutNoFooter,
        redirect: '/reportHome/report-index',
        children: [
        {
            path: 'report-total',
            name: 'reportTotal',
            component: () => import(/* webpackChunkName: "views" */ '@/views/report/total'),
            meta: {
                title: '进度统计',
                isAuth:true,
                keepAlive: true
            }
        },
        {
            path: 'report-student-module-total',
            name: 'reportStudentModuleTotal',
            component: () => import(/* webpackChunkName: "views" */ '@/views/report/studentModuleTotal'),
            meta: {
                title: '学生组件完成度统计',
                isAuth:true
            }
        },
        {
            path: 'report-student-view',
            name: 'reportStudentView',
            component: () => import(/* webpackChunkName: "views" */ '@/views/report/studentReportView'),
            meta: {
                title: '学生成长册预览',
                isAuth:true
            }
        },
        {
            path: 'report-student-select',
            name: 'reportStudentSelect',
            component: () => import(/* webpackChunkName: "views" */ '@/views/report/studentSelect'),
            meta: {
                title: '学生选择',
                isAuth:true
            }
        },
        {
            path: 'report-student-data',
            name: 'reportStudentData',
            component: () => import(/* webpackChunkName: "views" */ '@/views/report/studentData'),
            meta: {
                title: '上传数据',
                isAuth:true,
                // dingConfig:true
            }
        },
        {
            path: 'report-preview',
            name: 'reportPreview',
            component: () => import(/* webpackChunkName: "views" */ '@/views/report/preview'),
            meta: {
                title: '成长册预览',
                isAuth:false,
                iosBounce:false,
            }
        }
        ]
    },
    {
        path: '/reportConfig',
        name: 'reportConfig',
        component: LayoutNoFooter,
        redirect: '/workbench/index',
        children: [
            {
                path: 'report-template-list',
                name: 'reportTemplateList',
                component: () => import(/* webpackChunkName: "views" */ '@/views/reportTemplate/list'),
                meta: {
                    title: '成长册模版列表',
                    isAuth:true,
                }
            },
            {
                path: 'report-template-edit',
                name: 'reportTemplateEdit',
                component: () => import(/* webpackChunkName: "views" */ '@/views/reportTemplate/edit'),
                meta: {
                    title: '编辑成长册模版',
                    isAuth:true,
                    keepAlive: true
                }
            },
            {
                path: 'report-template-module-select',
                name: 'reportTemplateModuleSelect',
                component: () => import(/* webpackChunkName: "views" */ '@/views/reportTemplate/moduleSelect'),
                meta: {
                    title: '成长册组件选择',
                    isAuth:true
                }
            },
            ]
    }
]
