import {Base64} from "js-base64";
import website from "../config/website";
import {getToken} from "./auth";


/**
 * 通用工具类
 */
export default class func {


  /**
   * 拼接代理地址
   * @param url
   * @returns {string|string|*}
   */
  static getProxyUrl(url) {
    if(!url){
      return url
    }
    if(typeof url !== 'string' ){
      return '';
    }

    let  _url = url.toLowerCase();
    if (!url || _url.startsWith("http") || _url.startsWith("data:")) {
      return url;
    }

    const isUpload = _url.indexOf('uploadotherall') !== -1
    const isStatic = _url.indexOf('static') !== -1

    let _prefix = isUpload ? (website.propsHttpPrefix || '') : isStatic ? (website.staticHttpPrefix||'') : '';
    if(isUpload || isStatic){
      const _urlFlag = _url.startsWith("/");
      const _prefixFlag = _prefix.endsWith("/");
      if(_urlFlag){
        _url = _url.substring(1);
      }
      if(_prefixFlag){
        _prefix = _prefix.substr(0,_prefix.length - 1)
      }
      return _prefix + '/' + url;

    }
    let regExp = /^\w.*\.(png|jpg|gif|jpeg)/g;
    if(_url.match(regExp)){
      return require('@/assets/image/profile/' + url)
    }else {
      return url;
    }
  }


  /**
   * 不为空
   * @param val
   * @returns {boolean}
   */
  static notEmpty(val) {
    return !func.isEmpty(val);
  }

  static isMobile (){
    let array = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/gi)
    return !func.validateNull(array);
  }

  /**
   * 是否为定义
   * @param val
   * @returns {boolean}
   */
  static isUndefined(val) {
    return val === null || typeof val === 'undefined';
  }

  /**
   * 为空
   * @param val
   * @returns {boolean}
   */
  static isEmpty(val) {
    if (
      val === null ||
      typeof val === 'undefined' ||
      (typeof val === 'string' && val === '' && val !== 'undefined')
    ) {
      return true;
    }
    return false;
  }

  /**
   * 判断是否为空
   */
  static validateNull(val) {
    if(val == null) return true;
    if (typeof val == 'boolean') {
      return false;
    }
    if (typeof val == 'number') {
      return false;
    }
    if (val instanceof Array) {
      if (val.length == 0) return true;
    } else if (val instanceof Object) {
      if (JSON.stringify(val) === '{}') return true;
    } else {
      if (val == 'null' || val == 'undefined' || val == undefined || val == '') return true;
      return false;
    }
    return false;
  }

  /**
   * 强转int型
   * @param val
   * @param defaultValue
   * @returns {number}
   */
  static toInt(val, defaultValue) {
    if (this.isEmpty(val)) {
      return defaultValue === undefined ? -1 : defaultValue;
    }
    const num = parseInt(val, 0);
    return Number.isNaN(num) ? (defaultValue === undefined ? -1 : defaultValue) : num;
  }

  /**
   * Json强转为Form类型
   * @param obj
   * @returns {FormData}
   */
  static toFormData(obj) {
    const data = new FormData();
    Object.keys(obj).forEach(key => {
      data.append(key, Array.isArray(obj[key]) ? obj[key].join(',') : obj[key]);
    });
    return data;
  }

  /**
   * date类转为字符串格式
   * @param date
   * @param format
   * @returns {null}
   */
  static format(date, format = 'YYYY-MM-DD HH:mm:ss') {
    return date ? date.format(format) : null;
  }

  /**
   * 根据逗号联合
   * @param arr
   * @returns {string}
   */
  static join(arr) {
    return arr ? arr.join(',') : '';
  }

  /**
   * 根据逗号分隔
   * @param str
   * @returns {string}
   */
  static split(str) {
    return str ? String(str).split(',') : '';
  }

  /**
   * 获取header
   * @returns object
   */
  static getHeaders(){
    return  {
        'Authorization':`Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`,
        [website.tokenHeader] :'bearer ' + getToken()
      };
  }

  static doPreviewFile(path){
    if("https:" == document.location.protocol) {
      var wwwhost;
      if (window.location.port == ""){
        wwwhost = "https://" + window.location.hostname;
      } else {
        wwwhost = "https://" +window.location.hostname + ":" + window.location.port;
      }
      var temp = wwwhost;
      if (path.endWith("jpg") || path.endWith("jpeg") || path.endWith("png") || path.endWith("bmp") || path.endWith("gif") || path.endWith("mp4")){
        window.open("/" + path);
      } else {
        window.open('http://ood.gsjy.net/?i=14552&ssl=1&furl=' + encodeURIComponent(temp +  "/" + path));
      }
    } else {
      var wwwhost;
      if (window.location.port == ""){
        wwwhost = "http://" + window.location.hostname;
      } else {
        wwwhost = "http://" +window.location.hostname + ":" + window.location.port;
      }
      var temp = wwwhost;
      if (path.endWith("jpg") || path.endWith("jpeg") || path.endWith("png") || path.endWith("bmp") || path.endWith("gif") || path.endWith("mp4")){
        window.open("/" + path);
      } else {
        window.open('http://ood.gsjy.net/?i=14552&furl=' + encodeURIComponent(temp +  "/" +   path));
      }
    }
  }



  static timeAgo (timeStr) {
    var data = func.parserDate(timeStr);
    var dateTimeStamp = data.getTime()
    var minute = 1000 * 60;      //把分，时，天，周，半个月，一个月用毫秒表示
    var hour = minute * 60;
    var day = hour * 24;
    var week = day * 7;
    var month = day * 30;
    var year = month * 12;
    var now = new Date().getTime();   //获取当前时间毫秒
    var diffValue = now - dateTimeStamp;//时间差

    var result = "";
    if (diffValue < 0) {
      result = "" + "未来";
    }
    var minC = diffValue / minute;  //计算时间差的分，时，天，周，月
    var hourC = diffValue / hour;
    var dayC = diffValue / day;
    var weekC = diffValue / week;
    var monthC = diffValue / month;
    var yearC = diffValue / year;

    if (yearC >= 1) {
      result = " " + parseInt(yearC) + "年前"
    } else if (monthC >= 1 && monthC < 12) {
      result = " " + parseInt(monthC) + "月前"
    } else if (weekC >= 1 && weekC < 5 && dayC > 6 && monthC < 1) {
      result = " " + parseInt(weekC) + "周前"
    } else if (dayC >= 1 && dayC <= 6) {
      result = " " + parseInt(dayC) + "天前"
    } else if (hourC >= 1 && hourC <= 23) {
      result = " " + parseInt(hourC) + "小时前"
    } else if (minC >= 1 && minC <= 59) {
      result = " " + parseInt(minC) + "分钟前"
    } else if (diffValue >= 0 && diffValue <= minute) {
      result = "刚刚"
    }
    return result
  }

  // 调用：parserDate("2015-03-19 12::00:00")
  static parserDate = function (dateStr) {
    var t = Date.parse(dateStr.replace(/-/g, "/"))
    if (!isNaN(t)) {
      return new Date(t)
    } else {
      return new Date()
    }
  }

}
