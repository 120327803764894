/**
 * 是否有多个班级
 */

import store from '@/store/';

export default (el) =>{
        const squads = store.getters.squads||[]
        const flag = !(squads && squads.length > 0)
        el.style.display = flag ? '' : 'none'
}
