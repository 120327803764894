import request from '@/router/axios';

export const getSquad = () => {
  return request({
    url: '/api/ajlyey-report/reportsquad/getSquad',
    method: 'get',
    params: {
    }
  })
}

export const getPage = (squadId) => {
  return request({
    url: '/api/ajlyey-report/reportpage/getPage',
    method: 'get',
    params: {
      squadId
    }
  })
}

export const getPageByModuleId = (moduleId) => {
  return request({
    url: '/api/ajlyey-report/reportpage/getPageByModuleId',
    method: 'get',
    params: {
      moduleId
    }
  })
}

export const getTotal = (squadId) => {
  return request({
    url: '/api/ajlyey-report/reportpage/getTotal',
    method: 'get',
    params: {
      squadId
    }
  })
}

export const getStudentModuleList = () => {
  return request({
    url: '/api/ajlyey-report/reportmodule/getStudentModuleList',
    method: 'get',
    params: {
    }
  })
}

export const getHistoryReport = () => {
  return request({
    url: '/api/ajlyey-report/reportstudent/getHistoryReport',
    method: 'get',
    params: {
    }
  })
}

export const getPdfUrl = (studentId) => {
  return request({
    url: '/api/ajlyey-report/reportstudent/getPdfUrl',
    method: 'get',
    params: {
      studentId
    }
  })
}

export const getPdfUrl2 = (reportId) => {
  return request({
    url: '/api/ajlyey-report/reportstudent/getPdfUrlByReportId',
    method: 'get',
    params: {
      reportId
    }
  })
}



export const getModuleList = (pageId) => {
  return request({
    url: '/api/ajlyey-report/reportmodule/getModuleList',
    method: 'get',
    params: {
      pageId
    }
  })
}
export const getTemplateModuleList = (pageId) => {
  return request({
    url: '/api/ajlyey-report/reportmodule/getTemplateModuleList',
    method: 'get',
    params: {
      pageId
    }
  })
}

export const getStudentList = (pageModuleId) => {
  return request({
    url: '/api/ajlyey-report/reportstudentpagemodule/getStudentList',
    method: 'get',
    params: {
      pageModuleId
    }
  })
}

export const getSquadStudent = (squadId) => {
  return request({
    url: '/api/blade-user/getSquadStudent',
    method: 'get',
    params: {
      squadId
    }
  })
}

export const addModule = (query) => {
  return request({
    url: '/api/ajlyey-report/reportpage/addModule',
    method: 'post',
    data: query
  })
}

export const publishPage = (pageId) => {
  return request({
    url: '/api/ajlyey-report/reportpage/publishPage',
    method: 'post',
    params: {
      pageId
    }
  })
}

export const deletePage = (pageId) => {
  return request({
    url: '/api/ajlyey-report/reportpage/deletePage',
    method: 'post',
    params: {
      pageId
    }
  })
}

export const movePage = (query) => {
  return request({
    url: '/api/ajlyey-report/reportpage/movePage',
    method: 'post',
    data: query
  })
}


export const getModule = (pageModuleId) => {
  return request({
    url: '/api/ajlyey-report/reportpagemodule/getModule',
    method: 'get',
    params: {
      pageModuleId
    }
  })
}

export const saveProfileData = (query) => {
  return request({
    url: '/api/ajlyey-report/reportstudentprofile/saveData',
    method: 'post',
    data: query
  })
}

export const getProfileData = (pageModuleId,studentId) => {
  return request({
    url: '/api/ajlyey-report/reportstudentprofile/getProfileData',
    method: 'get',
    params: {
      pageModuleId,
      studentId
    }
  })
}

export const getPartData = (pageModuleId,studentIds) => {
  return request({
    url: '/api/ajlyey-report/reportstudentmoduledata/getPartData',
    method: 'get',
    params: {
      pageModuleId,
      studentIds
    }
  })
}
export const getMedalData = (pageModuleId,studentIds) => {
  return request({
    url: '/api/ajlyey-report/reportstudentmedaldata/getMedalData',
    method: 'get',
    params: {
      pageModuleId,
      studentIds
    }
  })
}


export const getModuleAuth = (pageModuleId) => {
  return request({
    url: '/api/ajlyey-report/reportmodule/getModuleAuth',
    method: 'get',
    params: {
      pageModuleId
    }
  })
}

export const savePartData = (query) => {
  return request({
    url: '/api/ajlyey-report/reportstudentmoduledata/savePartData',
    method: 'post',
    data: query
  })
}

export const saveMedalData = (query) => {
  return request({
    url: '/api/ajlyey-report/reportstudentmedaldata/saveMedalData',
    method: 'post',
    data: query
  })
}

export const getEvaluateList = (pageModuleId,studentIds) => {
  return request({
    url: '/api/ajlyey-report/reportevaluate/getEvaluateList',
    method: 'get',
    params: {
      pageModuleId,
      studentIds
    }
  })
}

export const getMedal = (pageModuleId,studentIds) => {
  return request({
    url: '/api/ajlyey-report/reportstudentmedal/getMedal',
    method: 'get',
    params: {
      pageModuleId,
      studentIds
    }
  })
}


export const saveEvaluate = (query) => {
  return request({
    url: '/api/ajlyey-report/reportevaluate/saveEvaluate',
    method: 'post',
    data: query
  })
}
export const saveMedal = (query) => {
  return request({
    url: '/api/ajlyey-report/reportstudentmedal/saveMedal',
    method: 'post',
    data: query
  })
}

export const getReportConfig = (tenantId) => {
  return request({
    url: '/api/ajlyey-report/reportconfig/getReportConfig',
    method: 'get',
    params: {
      tenantId
    }
  })
}

export const showReportHtml = (reportId) => {
  return request({
    url: '/api/ajlyey-report/reportstudent/showReportHtml',
    method: 'get',
    params: {
      reportId
    }
  })
}

export const getPageCount = (reportId) => {
  return request({
    url: '/api/ajlyey-report/reportpage/getPageCount',
    method: 'get',
    params: {
      reportId
    }
  })
}
