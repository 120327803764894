// import Layout from '@/page/layout/home'
import LayoutNoFooter from '@/page/layout/index'

export default [
    {
        path: '/fzpj',
        name: 'fzpj',
        component: LayoutNoFooter,
        redirect: '/fzpj/index',
        children: [
            {
                path: 'index',
                name: 'fzpj-index',
                component: () => import(/* webpackChunkName: "story" */ '@/views/fzpj/index'),
                meta: {
                    title: '发展评价',
                    isAuth: true,
                    iosBounce:false,//是否IOS弹跳（默认是）
                    rotateOption:{
                        rotate:false,//是否旋转
                        showStatusBar: false,//是否显示statusbar (iOS)
                        clockwise:true,//是否顺时针
                    }
                }
            },
            {
                path: 'yq',
                name: 'fzpj-yq',
                component: () => import(/* webpackChunkName: "story" */ '@/views/fzpj/yq'),
                meta: {
                    title: '邀请加入班级',
                    isAuth: false,
                    iosBounce:false,//是否IOS弹跳（默认是）
                    rotateOption:{
                        rotate:false,//是否旋转
                        showStatusBar: false,//是否显示statusbar (iOS)
                        clockwise:true,//是否顺时针
                    }
                }
            }
        ]
    },
]
