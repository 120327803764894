import activityRouters from './activity'
import baseRouters from './base'
import momentRouters from './moment'
import happyHomeRouters from './happy-home'
import squadAlbumRouters from './squad-album'
import leaveAlbumRouters from './leave'
import squadNoticeRouters from './squad-notice'
import workbenchRouters from './workbench'
import pictureBookRouters from './picture-book'
import storyRouters from './story'
import fzpjRouters from './fzpj'
export default [
    ...activityRouters,
    ...momentRouters,
    // ...happyHomeRouters,
    ...baseRouters,
    ...squadAlbumRouters,
    // ...leaveAlbumRouters,
    ...squadNoticeRouters,
    ...pictureBookRouters,
    ...workbenchRouters,
    ...storyRouters,
    ...fzpjRouters
]
