// import Layout from '@/page/layout/home'
import LayoutNoFooter from '@/page/layout/index'

export default [
    {
        path: '/picture-book',
        name: 'picture-book',
        component: LayoutNoFooter,
        redirect: '/picture-book/index',
        children: [
            {
                path: 'index',
                name: 'picture-book-index',
                component: () => import(/* webpackChunkName: "picture-book" */ '@/views/picture-book/index.vue'),
                meta: {
                    title: '绘本',
                    isAuth: true,
                    iosBounce:false,//是否IOS弹跳（默认是）
                    rotateOption:{
                        rotate:false,//是否旋转
                        showStatusBar: false,//是否显示statusbar (iOS)
                        clockwise:true,//是否顺时针
                    }
                }
            },
            {
                path: 'detail',
                name: 'picture-book-detail-index',
                component: () => import(/* webpackChunkName: "picture-book" */ '@/views/picture-book/pictureBookDetailIndex'),
                props: route => ({
                    id: route.query.id
                }),
                meta: {
                    title: '绘本详情',
                    isAuth: true,
                    keepAlive: false,
                    iosBounce:false,//是否IOS弹跳（默认是）
                }
            },
            {
                path: 'detail2',
                name: 'picture-books-detail-index',
                component: () => import(/* webpackChunkName: "picture-book" */ '@/views/picture-book/components/PictureBooKsDetail'),
                props: route => ({
                    id: route.query.id
                }),
                meta: {
                    title: '绘本详情',
                    isAuth: true,
                    keepAlive: true,
                    iosBounce:false,//是否IOS弹跳（默认是）
                }
            },
            {
                path: 'picture-book-play',
                name: 'picture-book-play',
                component: () => import(/* webpackChunkName: "picture-book" */ '@/views/picture-book/pictureBookPlay'),
                props: route => ({
                    id: route.query.id,
                    dubId: route.query.dubId,
                }),
                meta: {
                    title: '绘本播放',
                    isAuth: true,
                    iosBounce:false,//是否IOS弹跳（默认是）
                    keepScreenOn:true,//开启屏幕常亮
                    rotateOption:{
                        rotate:true,//是否旋转
                        showStatusBar: false,//是否显示statusbar (iOS)
                        clockwise:true,//是否顺时针
                    }
                }
            },
            {
                path: 'my-dub-page',
                name: 'my-dub-page',
                component: () => import(/* webpackChunkName: "picture-book" */ '@/views/picture-book/dubListIndex'),
                meta: {
                    title: '我的配音',
                    isAuth: true,
                    iosBounce:false,//是否IOS弹跳（默认是）
                }
            },
        ]
    },
]
