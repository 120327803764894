import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import func from "@/util/func";
import store from './store'
import lodash from 'lodash';
import Vant from 'vant';
import global from './util/global'
import {formatNull, loadStyle, parseTime} from './util/util'
import loading from "@/components/loading/loading";
import * as urls from '@/config/env';
import {
    iconfontUrl,
    iconfontVersion
} from '@/config/env';

import vue3videPlay from 'vue3-video-play'
import 'default-passive-events'

// 动态加载阿里云字体库
iconfontVersion.forEach(ele => {
    loadStyle(iconfontUrl.replace('$key', ele));
});

String.prototype.startWith = function (str) {
    var reg = new RegExp("^" + str);
    return reg.test(this);
}
String.prototype.endWith = function (s) {
    if (s == null || s == "" || this.length == 0 || s.length > this.length)
        return false;
    if (this.substring(this.length - s.length) == s)
        return true;
    else
        return false;
}
import 'vue3-video-play/dist/style.css'
import 'vant/lib/index.css';
import './assets/iconfont/iconfont.css'             // 自定义图标库
import './assets/css/style.scss'
import './assets/css/common.scss'
import {validatenull} from "@/util/validate";
import directive from "@/directive";
import { Lazyload } from 'vant';
import VueFireworks from 'vue3-damp-fireworks'


import VConsole from 'vconsole';
// const vConsole = new VConsole();

const app = createApp(App)
app.config.globalProperties.$getUrl = func.getProxyUrl;
app.config.globalProperties.$timeAgo = func.timeAgo;
app.config.globalProperties.$validateNull = validatenull
app.config.globalProperties.$formatNull = formatNull
app.config.globalProperties.$parseTime = parseTime
app.config.globalProperties.$parserDate = func.parserDate
app.use(vue3videPlay)
app.use(Vant)
app.use(router)
app.use(store)
app.use(lodash)
app.use(loading)
app.use(VueFireworks)
app.use(Lazyload, {
    lazyComponent: true,
});
app.provide('global', global);
directive(app)
app.mount('#app')

