<!--全局loading插件-->
<template>
  <van-overlay :show="showValue"
               :style="getRunTimeStyle"
               class-name="loading_overlay"
               z-index="999999">
    <div class="wrapper">
      <div id="loading_dom" ref="loadingRef" class="loading_block"/>
      <div v-if="false"  class="loading_text">加载中...</div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: 'loading'
}
</script>
<script setup>
import {computed, nextTick, reactive, ref, toRefs, watch,readonly} from "vue";
import lottie from "lottie-web";
import LoadingJson from "@/assets/json/loading.json";
import {useRouter} from "vue-router";

const loadingRef = ref(null)
const router = useRouter()

const getConfig = () => {
  return{
    text:'加载中...',
    speed:2,
    bg:'#f9f9f9'
  }
}

const state = reactive({
  showValue: false,
  jsonAnimation:undefined,
  config:getConfig()
})

const {showValue,jsonAnimation,config} = toRefs(state)

const getRunTimeStyle = computed(()=> {
  return {
    background: config.value.bg
  }
})

const _play = ()=> {
  if(!jsonAnimation.value){
    return;
  }
  jsonAnimation.value.setSpeed(config.value.speed)
  jsonAnimation.value.play();
}

const _stop = ()=> {
  if(!jsonAnimation.value){
    return;
  }
  jsonAnimation.value.stop();
}

const _destroy = ()=> {
  if(!jsonAnimation.value){
    return;
  }
  jsonAnimation.value.destroy();
  jsonAnimation.value = null;
}

watch(()=> showValue.value, val => {
  if(val){
    nextTick(async ()=> {
      await _init();
      _play();
    })
  }else{
    _destroy();
  }
},{immediate:true})

const _init = async() =>  {

  if(!jsonAnimation.value){
    jsonAnimation.value = lottie.loadAnimation({
      container: document.querySelector("#loading_dom"),  //进行播放的元素
      renderer: "svg",
      loop: true,  //循环播放
      autoplay: true,  //自动播放
      animationData: LoadingJson,  //要播放的文件
    });
  }
}

const _resetConfig = ()=> {
  config.value = getConfig();
}

const show = (_config={
  bg:config.value.bg,
  text:config.value.text,
  speed:1
}) => {
  _resetConfig();
  const {bg,text,speed} = _config
  if(bg){
    config.value.bg = bg
  }
  if(text){
    config.value.text = text
  }
  if(speed){
    config.value.speed = speed
  }
  showValue.value = true;
};

const hide = () => {
  showValue.value = false;
};

router.afterEach(()=> {
  hide()
})

defineExpose({
  showValue,
  show,
  hide
})
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-flow: column;

  .loading_block {
    width: 200px;
    height: 200px;
  }

  .loading_text {
    color: #666666;
    font-size: 12px;
  }
}

.loading_overlay{
  width:100vw;
  height:100vh;
  //backdrop-filter: saturate(200%) blur(20px);
  //background: transparent;
}

</style>


