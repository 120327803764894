import request from '@/router/axios';

/**
 * 获取钉钉鉴权的参数
 * @returns {AxiosPromise}
 */
export const getConfigParams = (url) => {
  return request({
    url: '/api/blade-auth/ding/config-params',
    method: 'post',
    params: {
      url
    }
  })
}
