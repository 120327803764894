// import Layout from '@/page/layout/home'
import LayoutNoFooter from '@/page/layout/index'

export default [
    {
        path: '/base',
        name: 'base',
        component: LayoutNoFooter,
        children: [
            {
                path: 'my-children',
                name: 'my-children',
                component: () => import(/* webpackChunkName: "base" */ '@/views/my/base/my-children'),
                meta: {
                    title: '我的宝贝',
                    isAuth: true,
                }
            },
            {
                path: 'my-squads',
                name: 'my-squads',
                component: () => import(/* webpackChunkName: "base" */ '@/views/my/base/my-squads'),
                meta: {
                    title: '我的班级',
                    isAuth: true,
                }
            },
            {
                path: 'squad-detail',
                name: 'squad-detail',
                component: () => import(/* webpackChunkName: "base" */ '@/views/my/base/squad-detail'),
                props: route => ({
                    id: route.query.id,
                }),
                meta: {
                    title: '班级详情',
                    isAuth: true,
                }
            },
            {
                path: 'banner-detail-index',
                name: 'banner-detail-index',
                component: () => import(/* webpackChunkName: "base" */ '@/views/index/banner/detail'),
                props: route => ({
                    id: route.query.id,
                }),
                meta: {
                    title: 'banner详情',
                    isAuth: true,
                }
            },
            {
                path: 'user-info',
                name: 'user-info',
                component: () => import(/* webpackChunkName: "base" */ '@/views/my/userInfo'),
                props: route => ({
                    userId: route.query.userId,
                }),
                meta: {
                    title: '用户信息',
                    isAuth: true,
                }
            }

        ]
    },
]
